




































































import { Component, Vue, Prop } from "vue-property-decorator";
import { intersection } from "lodash";

import {
  AbTestMetricType,
  AbTestResultType,
  AbTestResultModel,
} from "@/ab-tests/models/AbTestConfigurationModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Component
export default class AbTestResultSummary extends Vue {
  @Prop() abTestResult!: AbTestResultModel | null;
  @Prop() isResultLoading!: boolean;
  @Prop() targetMetric!: AbTestMetricType;
  @Prop() dayNumber!: number;

  get metrics(): Array<AbTestMetricType> {
    return this.abTestResult?.metrics.map(({ metric }) => metric) ?? [];
  }

  get groups(): Record<string, Record<AbTestMetricType, number>> {
    return this.abTestResult?.groups ?? {};
  }

  get groupResultTypes(): Array<AbTestResultType> {
    return Object.values(this.groups).map((groupResult) =>
      ConfigUtil.getTestGroupPerformance(this.targetMetric, groupResult)
    );
  }

  get winnerGroup(): { value: number; name: string } {
    return Object.entries(this.groups).reduce(
      (result, [groupName, values]) => {
        if (result.value === null || values[this.targetMetric] > result.value) {
          result.value = values[this.targetMetric];
          result.name = groupName;
        }

        return result;
      },
      { value: 0, name: "" }
    );
  }

  get hasPositiveGroup(): boolean {
    return this.groupResultTypes.includes(AbTestResultType.POSITIVE);
  }

  get hasInconsistentPositiveGroup(): boolean {
    return this.groupResultTypes.includes(
      AbTestResultType.INCONSISTENT_POSITIVE
    );
  }

  get summary(): string {
    let message = this.$lang("abTest.summary.caption");
    let targetMetric = this.$lang(
      `abTest.metricType.${this.targetMetric.toLowerCase()}`
    );

    if (
      intersection(this.groupResultTypes, [
        AbTestResultType.POSITIVE,
        AbTestResultType.INCONSISTENT_POSITIVE,
      ]).length
    ) {
      message += this.$lang(
        "abTest.summary.positive",
        this.winnerGroup.name,
        this.formatGroupValue(this.winnerGroup.value),
        targetMetric,
        this.dayNumber
      );

      if (this.groupResultTypes.includes(AbTestResultType.POSITIVE)) {
        return message;
      }

      return (message += this.$lang(
        "abTest.summary.inconsistentPositive",
        this.winnerGroup.name
      ));
    }

    if (this.groupResultTypes.includes(AbTestResultType.UNCERTAIN)) {
      return (message += this.$lang(
        "abTest.summary.uncertain",
        targetMetric,
        this.dayNumber
      ));
    }

    return (message += this.$lang(
      "abTest.summary.negative",
      targetMetric,
      this.dayNumber
    ));
  }

  formatGroupValue(value: number): string {
    return `${(value * 100).toFixed(2)}%`;
  }
}
