var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-6 mb-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-h6",attrs:{"tag":"h2","cols":"auto"}},[_vm._v(_vm._s(_vm.$lang("abTest.segments")))]),(_vm.generateReportBtnVisible)?_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$lang("abTest.generateReport"))+" ")])]}}],null,false,4137130579)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.cohortReports),function(reportType){return _c('v-list-item',{key:reportType,attrs:{"link":""},on:{"click":function($event){return _vm.goToReport(reportType)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getReportName(reportType)))])],1)}),1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.abTest.segmentStatistics,"items-per-page":-1,"sort-by":"usersCount","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'segment_update',
            params: {
              id: _vm.applicationId,
              segmentId: item.id,
            },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.usersCount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.usersCount === +item.usersCount ? item.usersCount : _vm.$lang("notAvailable"))+" ")]}},{key:"item.completeness",fn:function(ref){
          var item = ref.item;
return [(item.completeness >= 100)?_c('v-chip',{attrs:{"color":"success","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(_vm._s(_vm.$lang("abTest.completed"))+" ")],1):_c('div',[_vm._v(" "+_vm._s(_vm.getCompleteness(item))+" ")])]}},{key:"item.usersShare",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUsersShare(item))+" ")]}},{key:"item.relativeShare",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRelativeShare(item))+" ")]}}],null,true)}),(_vm.isStatisticCalculated && _vm.abTestResult)?_c('ab-test-result-summary',{staticClass:"mt-6 mb-2",attrs:{"ab-test-result":_vm.abTestResult,"is-result-loading":_vm.isResultLoading,"target-metric":_vm.abTest.metric,"day-number":_vm.abTest.dayNumber}}):_vm._e(),(_vm.isStatisticCalculated)?_c('h2',{staticClass:"text-h6 mt-6 mb-2"},[_vm._v(" "+_vm._s(_vm.$lang("abTest.statisticalSignificance"))+" ")]):_vm._e(),(_vm.isStatisticCalculated)?_c('v-card',{staticClass:"rounded elevation-4"},[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.abTestMetrics),function(abTestMetric){return _c('v-tab',{key:abTestMetric.type,attrs:{"disabled":abTestMetric.disabled}},[_vm._v(" "+_vm._s(_vm.$lang(("abTest.metricType." + (abTestMetric.type.toLowerCase()))))+" "),(_vm.abTest.metric === abTestMetric.type)?_c('v-badge',{attrs:{"dot":"","color":"red","offset-x":-2,"offset-y":-2}}):_vm._e()],1)}),1),_c('v-tabs-items',{staticClass:"pa-4",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.abTestMetrics),function(abTestMetric,index){return _c('v-tab-item',{key:abTestMetric.type,attrs:{"transition":false}},[(_vm.tabs === index && _vm.abTestResult)?_c('ab-test-statistic',{attrs:{"ab-test":_vm.abTest,"metricType":abTestMetric.type,"applicationId":_vm.applicationId,"configId":_vm.configId,"viewMode":_vm.viewMode,"ab-test-result":_vm.abTestResult},on:{"changeView":_vm.changeView}}):_vm._e()],1)}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }