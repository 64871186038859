












































import { Component, Vue, Prop } from "vue-property-decorator";
import AbTestConfigurationModel, {
  AbTestChartDataItemModel,
  AbTestChartModel,
  AbTestMetricType,
  AbTestResultModel,
} from "@/ab-tests/models/AbTestConfigurationModel";
import Chart from "@/chart/components/Chart.vue";
import { ChartName } from "@/chart/models/ChartModel";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";

@Component({
  components: {
    Chart,
  },
})
export default class AbTestChartStatistic extends Vue {
  @Prop() metricType!: AbTestMetricType;
  @Prop() viewMode!: number;
  @Prop() chart!: AbTestChartModel;
  @Prop() abTestResult!: AbTestResultModel;

  isOpen = false;

  get chartName(): ChartName {
    return {
      ARPU: ChartName.AB_TEST_ARPU_CHART,
      RETENTION_RATE: ChartName.AB_TEST_RETENTION_RATE_CHART,
      TIME_SPENT: ChartName.AB_TEST_TIME_SPENT_CHART,
    }[this.metricType];
  }

  get prevValidationChartName(): ChartName {
    return {
      ARPU: ChartName.AB_TEST_ARPU_PREV_VALIDATION_CHART,
      RETENTION_RATE: ChartName.AB_TEST_RETENTION_RATE_PREV_VALIDATION_CHART,
      TIME_SPENT: ChartName.AB_TEST_TIME_SPENT_PREV_VALIDATION_CHART,
    }[this.metricType];
  }

  get abTest(): AbTestConfigurationModel | ExternalTestModel {
    // TODO: temp solution for detecting test type
    return this.$route.name === "external_test"
      ? this.$store.state.externalTestStore.test
      : this.$store.state.abTestConfig.abTestConfig;
  }

  get lastDayData(): AbTestChartDataItemModel | undefined {
    return this.chart.items[this.dayNumber];
  }

  get alertType(): string {
    if (!this.lastDayData) {
      return "error";
    }

    if (!this.lastDayData.relEffect) {
      return "warning";
    }

    return this.lastDayData.relEffect > 0 ? "success" : "error";
  }

  get dayNumber(): number {
    return this.abTest.dayNumber ?? 1;
  }

  get alertMessage(): string {
    const testTypeName = this.$lang(
      `testType.${this.chart.testType.toLowerCase()}`
    );
    let message = "abTestSegmentResult.";

    if (!this.lastDayData) {
      return this.$lang("abTestSegmentResult.noData", this.dayNumber);
    }

    if (!this.lastDayData.relEffect) {
      message += "none";
    } else {
      message += this.lastDayData.relEffect > 0 ? "positive" : "negative";
    }

    return this.$lang(
      message,
      this.chart.segmentName,
      this.abTest.controlGroupName,
      Math.abs(this.lastDayData.relEffect ?? 0),
      this.$lang(`abTestConfig.metricType.${this.metricType.toLowerCase()}`),
      this.dayNumber,
      testTypeName
    );
  }

  get alertMessageDetails(): string {
    const pValue = this.lastDayData?.pValue ?? 0;
    const pValuePercent = (pValue * 100).toFixed(2);
    let message = "abTestSegmentResult.";

    if (!this.lastDayData?.relEffect) {
      message += "noneDetails";
    } else {
      message +=
        this.lastDayData.relEffect > 0 ? "positiveDetails" : "negativeDetails";
    }

    return this.$lang(message, this.chart.alfa, pValue, pValuePercent);
  }

  get prevValidationChartTitle(): string {
    return this.$lang(
      "abTestSegmentResult.prevValidationChartTitle",
      this.chart.segmentName
    );
  }

  get chartTitle(): string {
    return this.$lang("abTestSegmentResult.chartTitle", this.chart.segmentName);
  }
}
