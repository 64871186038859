


































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import AbTestStatistic from "@/ab-tests/components/AbTestStatistic.vue";
import AbTestResultSummary from "@/ab-tests/components/AbTestResultSummary.vue";
import AbTestConfigurationModel, {
  AbTestMetricType,
  AbTestResultModel,
  AbTestType,
  SegmentStatisticModel,
} from "@/ab-tests/models/AbTestConfigurationModel";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";
import { AggregationPeriod, ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";
import { FilterId } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import EncodeUtil from "@/shared/utils/EncodeUtil";

@Component({
  components: {
    AbTestStatistic,
    AbTestResultSummary,
  },
})
export default class AbTestSegmentStatistic extends Vue {
  @Prop() abTest!: AbTestConfigurationModel | ExternalTestModel;
  @Prop() abTestResult!: AbTestResultModel | null;
  @Prop() isResultLoading!: boolean;

  readonly headers = [
    {
      text: this.$lang("commonConfig.segmentName"),
      align: "start",
      value: "name",
    },
    {
      text: this.$lang("abTest.usersCount"),
      value: "usersCount",
    },
    {
      text: this.$lang("abTest.completeness"),
      value: "completeness",
    },
    {
      text: this.$lang("abTest.usersShare"),
      value: "usersShare",
    },
    {
      text: this.$lang("abTest.relativeShare"),
      value: "relativeShare",
      sortable: false,
    },
  ];
  readonly abTestMetrics = [
    {
      type: AbTestMetricType.RETENTION_RATE,
      disabled: false,
    },
    {
      type: AbTestMetricType.ARPU,
      disabled: false,
    },
    {
      type: AbTestMetricType.TIME_SPENT,
      disabled: false,
    },
  ];
  tabs = 0;
  viewMode = 0;

  get generateReportBtnVisible(): boolean {
    return this.abTest.abTestType !== AbTestType.AUDIENCE;
  }

  get configId(): string {
    return this.$route.params.configId;
  }

  get cohortReports(): Array<ReportType> {
    const reportIdsWithSegmentFilter = [
      ReportType.ARPU,
      ReportType.RETURN_RATE,
      ReportType.MEASURES_LITE,
      ReportType.TIME_SPENT,
      ReportType.METRICS,
    ];

    return (
      this.$store.state.userStore.currentUser.reportAccessByApp[
        this.applicationId
      ]?.filter((id: ReportType) => reportIdsWithSegmentFilter.includes(id)) ??
      []
    );
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get totalUsers(): number {
    return this.abTest.segmentStatistics.reduce(
      (result, { usersCount }) =>
        usersCount ? (result += usersCount) : result,
      0
    );
  }

  get isStatisticCalculated(): boolean {
    return this.abTest.status === ConfigsStatus.STATISTICS_RECALCULATED;
  }

  @Watch("abTest.metric", { immediate: true })
  watchAbTestMetric(value: AbTestMetricType) {
    this.tabs = this.abTestMetrics.findIndex(
      ({ type }: { type: AbTestMetricType }) => type === value
    );
  }

  getCompleteness(item: SegmentStatisticModel): string {
    return item.completeness === null
      ? this.$lang("notAvailable")
      : `${Math.floor(item.completeness)}%`;
  }

  getUsersShare(item: SegmentStatisticModel): string {
    if (item.usersShare === undefined || item.usersShare === null) {
      return this.$lang("notAvailable");
    }

    const value = Math.floor(item.usersShare * 10000) / 10000;

    return value ? `${value.toFixed(4)}%` : "<0.0001%";
  }

  getRelativeShare(item: SegmentStatisticModel): string {
    if (item.usersCount === undefined || item.usersCount === null) {
      return this.$lang("notAvailable");
    }

    if (this.totalUsers === 0) {
      return "0%";
    }

    return ((item.usersCount / this.totalUsers) * 100).toFixed(2) + "%";
  }

  getReportName(reportType: ReportType): string {
    return ReportUtil.getReportNameByReportType(reportType);
  }

  async goToReport(reportType: ReportType) {
    if (!this.$store.state.reportStore.isCommonReportFilterEnabled) {
      this.$store.commit("toggleCommonReportFilter");
    }

    const twoDaysAgo = DateUtil.twoDaysAgo();
    const to: string =
      this.abTest.activeTill ??
      (this.abTest.activeSince && twoDaysAgo < this.abTest.activeSince
        ? this.abTest.activeSince
        : twoDaysAgo);
    const query: Record<string, any> = {
      date: {
        id: FilterId.ATTRIBUTION_DATE_VALUE,
        from: this.abTest.activeSince,
        to,
      },
      dayLimit: this.abTest.dayNumber ? this.abTest.dayNumber + 1 : undefined,
      groupByFilter: {
        aggregationPeriod: AggregationPeriod.DAY,
        groupBy: [FilterId.SEGMENT],
      },
      filter: [
        {
          id: FilterId.SEGMENT,
          values: this.abTest.segmentStatistics.map(
            ({ id }: SegmentStatisticModel) => id.toString()
          ),
        },
      ],
    };

    const compressedQueryParams = await EncodeUtil.compressRouteQuery(
      JSON.stringify(query)
    );

    const route = this.$router.resolve({
      name: reportType,
      query: {
        data: compressedQueryParams,
      },
      params: {
        id: this.applicationId,
      },
    });

    window.open(route.href, "_blank");
  }

  changeView(num: number) {
    this.viewMode = num;
  }
}
