






















































































































import { Component, Vue, Prop } from "vue-property-decorator";

import AbTestConfigurationModel, {
  AbTestMetricType,
  AbTestPeriodValidationStatus,
  AbTestResultSummary,
} from "@/ab-tests/models/AbTestConfigurationModel";

@Component
export default class AbTestGroupResultTable extends Vue {
  @Prop() abTest!: AbTestConfigurationModel;
  @Prop() summary!: Array<AbTestResultSummary>;
  @Prop() metric!: AbTestMetricType;

  get targetMetricHeader(): string {
    return `${this.$lang(`abTest.metricType.${this.metric.toLowerCase()}`)} ${
      this.abTest.dayNumber
    }`;
  }

  get prevPeriodTargetMetricHeader(): string {
    return `${this.$lang(
      `abTest.metricType.${this.metric.toLowerCase()}`
    )} ${this.$lang(`abTest.prev`)} ${
      this.abTest.dayNumber ? this.abTest.dayNumber + 1 : ""
    }`;
  }

  get hasPrevPeriod(): boolean {
    return !!this.summary[0]?.prevPeriod;
  }

  getResultCellBg(group: AbTestResultSummary): string {
    if (!group.prevPeriod?.result) {
      return "";
    }

    return {
      [AbTestPeriodValidationStatus.INVALID]: "red--text",
      [AbTestPeriodValidationStatus.VALID]: "green--text",
    }[group.prevPeriod.result];
  }

  formatValue(value: number | null, fractionDigits = 3, postfix = ""): string {
    return value === null
      ? ""
      : value.toLocaleString(this.$vuetify.lang.current, {
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits,
        }) + postfix;
  }

  convertToPercents(value: number | null): string {
    return this.formatValue(value && value * 100, 2, "%");
  }

  targetMetricValue(value: number): string | number {
    return {
      ARPU: () => this.formatValue(value),
      RETENTION_RATE: () => this.convertToPercents(value),
      TIME_SPENT: () => value,
    }[this.abTest.metric as AbTestMetricType]();
  }
}
