



































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import AbTestGroupResultTable from "@/ab-tests/components/AbTestGroupResultTable.vue";
import AbTestChartStatistic from "@/ab-tests/components/AbTestChartStatistic.vue";

import AbTestConfigurationModel, {
  AbTestChartModel,
  AbTestMetricType,
  AbTestResultModel,
  AbTestResultSummary,
  AbTestStatisticalSignificanceTestType,
  AbTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import { ExternalTestModel } from "@/ab-tests/models/ExternalTest";

@Component({
  components: {
    AbTestChartStatistic,
    AbTestGroupResultTable,
  },
})
export default class AbTestStatistic extends Vue {
  @Prop() metricType!: AbTestMetricType;
  @Prop() applicationId!: string;
  @Prop() configId!: string;
  @Prop({ default: 0 }) viewMode!: number;
  @Prop() abTest!: AbTestConfigurationModel | ExternalTestModel;
  @Prop() abTestResult!: AbTestResultModel;

  get localViewMode(): number {
    return this.viewMode;
  }

  set localViewMode(value) {
    this.$emit("changeView", value);
  }

  get downloadLink(): string {
    return `/api/ab-tests/app/${this.applicationId}/abtest/configs/${
      this.configId
    }/metrics/${this.metricType}/csv${
      this.testType ? `?test=${this.testType}` : ""
    }`;
  }

  get downloadPrevPeriodLink(): string {
    return `${this.downloadLink}${this.testType ? "&" : "?"}period=PREV`;
  }

  get abTestCharts(): Array<AbTestChartModel> {
    return this.$store.state.abTestConfig.abTestCharts;
  }

  get abTestResultSummary(): Array<AbTestResultSummary> {
    return this.$store.state.abTestConfig.abTestResultSummary;
  }

  get isChartsLoading(): boolean {
    return this.$store.state.abTestConfig.abTestChartsLoading;
  }

  get testType(): AbTestStatisticalSignificanceTestType | null {
    if (this.metricType === AbTestMetricType.RETENTION_RATE) {
      return null;
    }

    return this.hasAbTestPermutationTestFeature
      ? AbTestStatisticalSignificanceTestType.PERMUTATION_TEST
      : AbTestStatisticalSignificanceTestType.T_TEST;
  }

  get hasAbTestPermutationTestFeature(): boolean {
    return this.$store.getters.applicationsById.get(this.applicationId)
      ?.hasAbTestPermutationTestFeature;
  }

  get prevPeriodDownloadVisible(): boolean {
    return this.abTest.abTestType === AbTestType.AUDIENCE;
  }

  @Watch("applicationId", { immediate: true })
  @Watch("configId")
  getAbTestCharts() {
    this.$store.dispatch("getAbTestCharts", {
      applicationId: this.applicationId,
      abTestId: this.configId,
      metricType: this.metricType,
      testType: this.testType,
    });
  }
}
